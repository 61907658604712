import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import EditIcon from '@material-ui/icons/Edit';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { assetTypeValues, backendDateFormat, fieldDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, replaceEmptyNumber, checkPermission } from "../../utils_v2";
import { Skeleton } from "@material-ui/lab";
import { PageLoader } from "../../shared_elements";
import { getPermissionKey } from "..";
import { trackActivity } from "../../../utils/mixpanel";
import { jetStreamInstance , hanwahaInstance, halcyonInstance} from "../../../shared/components";
const UtilizationInfo = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [utilization, setUtilaization] = useState();
    const [utilizationsData, setUtilizationsData] = useState();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, seterror] = useState({});
    useEffect(() => {
        getUtilizationsData('skeletonLoader')
    }, []);
    const getUtilizationsData = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/asset/${params?.type}/${params?.aircraft_slug}/utilization/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setUtilizationsData(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onEditUtilizationData = (loaderType) => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            last_utilization_updated: moment(utilizationsData.last_utilization_updated).isValid() ? moment(utilizationsData.last_utilization_updated).isValid() && moment(utilizationsData.last_utilization_updated).isSameOrBefore(moment(), 'day') ? '' : "As of date can't be future Date" : 'Please Enter Date',
            tsn: utilizationsData.tsn ? utilizationsData?.tsn.toString()?.trim()?.length ? '' : 'Please Enter TSN' : 'Please Enter TSN',
            csn: utilizationsData.csn ? utilizationsData?.csn.toString()?.trim()?.length ? '' : 'Please Enter CSN' : 'Please Enter CSN'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
            if (utilizationsData) {
                globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/utilization/`, replaceEmptyNumber(utilizationsData, ['average_monthly_cycles', 'average_monthly_hours','egt_margin']))
                    .then(response => {
                        if (checkApiStatus(response)) {
                            setEdit(false); setLoading(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Console Management', { page_title: 'Utlization Info', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Item Edited', event_desc: 'Edited Utlization Info data from Edit Utlization Info form' });
                        }
                        loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
                    })
            }
        } else {
            seterror(validationInputs)
        }
    }
    const onFieldChange = (key, value) => {
        setUtilizationsData({ ...utilizationsData, [key]: value })
    }
    const onResetError = (key) => seterror({ ...error, [key]: '' })
    return (
        <Paper className="console-forms-fields" style={{ padding: '20px 10px', marginBottom: '15px', position: 'relative' }}>
            {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && !isEdit && !jetStreamInstance?
                <span className="edit-basic-info" style={{ top: "0px", right: "1px" }}>
                    <EditIcon onClick={() => setEdit(true)} fontSize="small" color='primary' />
                </span> : null
            }
            <Grid container spacing={1}>
                <Grid item md={4} lg={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            disabled={!isEdit}
                            margin="normal"
                            name="last_utilization_updated"
                            label="As of Date"
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true }}
                            minDate={moment().subtract(25, 'years')}
                            value={utilizationsData?.last_utilization_updated || null}
                            onChange={(data, value) => { onFieldChange('last_utilization_updated', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('last_utilization_updated') }}
                            inputVariant='outlined'
                            error={error.last_utilization_updated ? true : false}
                            helperText={error.last_utilization_updated || ''}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4} lg={2}>
                    <TextField
                        required
                        disabled={!isEdit}
                        name='tsn'
                        label='TSN'
                        value={utilizationsData?.tsn || ''}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        error={error.tsn ? true : false}
                        helperText={error.tsn || ''}
                        onFocus={() => onResetError('tsn')}
                    />
                </Grid>
                <Grid item md={4} lg={2}>
                    <TextField
                        required
                        disabled={!isEdit}
                        name='csn'
                        label='CSN'
                        value={utilizationsData?.csn || ''}
                        fullWidth
                        inputProps={{ maxlength: 10 }}
                        margin="normal"
                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        error={error.csn ? true : false}
                        helperText={error.csn || ''}
                        onFocus={() => onResetError('csn')}
                    />
                </Grid>
                <Grid item md={4} lg={2}>
                    <TextField
                        disabled={!isEdit}
                        name='average_monthly_hours'
                        label='Avg. Monthly Hours'
                        value={utilizationsData?.average_monthly_hours || ''}
                        fullWidth
                        inputProps={{ maxlength: 10 }}
                        margin="normal"
                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('average_monthly_hours', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item md={4}lg={3}>
                    <TextField
                        disabled={!isEdit}
                        name='average_monthly_cycles'
                        label='Avg. Monthly Cycles'
                        value={utilizationsData?.average_monthly_cycles || ''}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxlength: 10 }}
                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('average_monthly_cycles', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
                {hanwahaInstance || halcyonInstance ?
                <Grid item md={4}lg={3}>
                    <TextField
                        disabled={!isEdit}
                        name='egt_margin'
                        label='EGT Margin'
                        value={utilizationsData?.egt_margin || ''}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => onFieldChange('egt_margin', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>:null}
            </Grid>
            {isEdit ?
                <ul className="list-inline" style={{ paddingTop: '10px' }}>
                    <li className="list-inline-item">
                        <Button onClick={() => { setEdit(false); getUtilizationsData('pageLoader'); seterror('') }} color="primary" size='small' variant="outlined">Cancel</Button>
                    </li>
                    <li className="list-inline-item">
                        <Button onClick={onEditUtilizationData} color="primary" size='small' variant="contained">SAVE</Button>
                    </li>
                </ul> : null
            }
            {isLoading ? <PageLoader /> : null}
        </Paper>
    )
}
export default withRouter(UtilizationInfo);